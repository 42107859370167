// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserPageWrapper{display:flex;flex-direction:column;width:100%;height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;background-color:#fff;overflow:hidden auto;padding-bottom:200px}.UserPageWrapper .UserPageLogo{background:linear-gradient(180deg, #FF3CED 0%, #710D70 100%);display:flex;align-items:center;width:100%;height:150px;padding-left:7.5%;font-size:35px}.UserPageWrapper .UserPageLogo img{height:40%}.UserPageWrapper.__isMobile .UserPageLogo{margin-left:0}.UserPageWrapper.__isMobile .UserPageLogo{margin-left:0}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#b823ba",
	"lightPrimary": "#e465e6",
	"secondaryColor": "#1a1a1a",
	"lightSecondary": "#474747"
};
module.exports = exports;
