// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotificationDetailScreen textarea{resize:none}.NotificationDetailScreen .NotificationSendEmail{display:flex;align-items:center;gap:10px}.NotificationDetailScreen .NotificationSendEmail p{margin:0;font-weight:600}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#b823ba",
	"lightPrimary": "#e465e6",
	"secondaryColor": "#1a1a1a",
	"lightSecondary": "#474747"
};
module.exports = exports;
