// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AcceptInviteWrapper{display:flex;flex-direction:column;width:100%;height:100%;background-image:linear-gradient(180deg, #FF3CED 0%, #710D70 100%);background-position:center;background-size:cover;background-repeat:no-repeat;background-color:#fff;overflow:hidden auto}.AcceptInviteWrapper .AcceptInviteLogo{display:flex;align-items:center;width:100%;height:150px;margin-left:10%;font-size:35px;margin-top:100px}.AcceptInviteWrapper .AcceptInviteContent{display:flex;flex-direction:column;justify-content:center;height:calc(100% - 150px);width:100%;margin-left:10%}.AcceptInviteWrapper .AcceptInviteContent .AcceptInviteWelcome{font-size:20px;font-weight:700;color:#fff}.AcceptInviteWrapper .AcceptInviteContent .AcceptInviteSubtitle{font-size:12px;color:#fff}.AcceptInviteWrapper .AcceptInviteContent .AcceptInviteLogin{font-size:12px;margin-top:5px;display:flex;justify-content:flex-end}.AcceptInviteWrapper .AcceptInviteContent .isLink{cursor:pointer}.AcceptInviteWrapper .AcceptInviteContent .isLink:hover{color:#1a1a1a}.AcceptInviteWrapper .AcceptInviteContent .AcceptInviteButton{margin-top:30px;width:100%;background-color:#1a1a1a}.AcceptInviteWrapper .AcceptInviteContent form{margin-top:20px;width:350px}.AcceptInviteWrapper .AcceptInviteContent form em{color:#1a1a1a;font-weight:700}.AcceptInviteWrapper.__isMobile{padding:0 20px}.AcceptInviteWrapper.__isMobile .AcceptInviteLogo{margin-left:0}.AcceptInviteWrapper.__isMobile .AcceptInviteLogo,.AcceptInviteWrapper.__isMobile .AcceptInviteContent{margin-left:0}.AcceptInviteWrapper.__isMobile form{width:100%}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#b823ba",
	"lightPrimary": "#e465e6",
	"secondaryColor": "#1a1a1a",
	"lightSecondary": "#474747"
};
module.exports = exports;
