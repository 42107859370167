// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserMainImage{height:100%;position:relative;cursor:pointer;border:2px dashed #a6a5a6}.UserMainImage:hover{border-color:#40a9ff}.UserMainImage:hover .GenericImageUploadOverlay:not(.--visible){opacity:1;background-color:#00000080;color:#fff}.UserMainImage .UserImage{height:100%;width:100%;background-repeat:no-repeat;background-size:contain;background-position:center}.UserMainImage .GenericImageDelete{display:flex;justify-content:center;align-items:center;position:absolute;right:3px;top:3px;min-height:25px;min-width:25px;border-radius:50%;background-color:#ddd;border:none;box-shadow:0 0 4px 2px #00000080;cursor:pointer;transition:200ms ease-in-out}.UserMainImage .GenericImageDelete em{font-size:12px}.UserMainImage .GenericImageDelete:hover{color:#40a9ff}.confirmForget .ant-modal-body{display:flex;flex-direction:column;align-items:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#b823ba",
	"lightPrimary": "#e465e6",
	"secondaryColor": "#1a1a1a",
	"lightSecondary": "#474747"
};
module.exports = exports;
