// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"primaryColor": "#b823ba",
	"lightPrimary": "#e465e6",
	"secondaryColor": "#1a1a1a",
	"lightSecondary": "#474747"
};
module.exports = exports;
