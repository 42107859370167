import React from "react";
import { connect } from "react-redux";
import {
	delayedDispatch,
	setBreadcrumb,
	setLoader,
	setTitle,
	updateCrumb,
} from "store/actions";
import { Props } from "./types";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { ContentWrapper, Icon, Table } from "components";
import {
	Col,
	Drawer,
	Input,
	notification as Notification,
	Row,
	Switch,
} from "antd";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import { translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import "./styles.scss";

class AlbumDetail extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props);

		this.state = {
			item: null,
			musics: [],
			tempMusic: {},
			openMusicDrawer: false,
			filesToDelete: [],
			language: "pt",
			_active: false,
			drawerLoading: false,
			hasUnsavedFields: false,
			sidebarLanguage: 'pt',
		};
	}

	componentDidMount() {
		const { item, language } = this.state;
		const { dispatch, match } = this.props;

		dispatch(
			setTitle(
				`${Strings.sidebar.albuns} - ${item?.name || Strings.albuns.newItem}`
			)
		);

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.albuns,
							route: "/albuns",
							icon: "grid",
						},
						{
							text:
								match.params.id === "new"
									? Strings.albuns.newItem
									: this.state.item?.name,
							icon:
								match.params.id === "new"
									? "plus"
									: "pencil-outline",
						},
					],
					actions: [
						{
							type: "switch",
							text: Strings.albuns.published,
							value: this.state._active,
							onClick: () =>
								this.setState((prevState: any) => ({
									_active: !prevState._active,
								})),
							small: {
								text: true,
								switch: true,
							},
						},
						{
							type: "language",
							value: language,
							separator: "left",
							onChange: (lang: any) =>
								this.setState({ language: lang }),
						},
						{
							type: "button",
							text: Strings.generic.save,
							onClick: () => this.submitItem(),
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields
								? "BreadcrumbButtonSuccess"
								: "",
							separator: "left",
							isSave: true,
						},
					],
				};
			})
		);

		this.getData();
	}

	componentDidUpdate() {
		const { item } = this.state;
		const { dispatch } = this.props;

		dispatch(updateCrumb());
		dispatch(
			setTitle(
				`${Strings.sidebar.albuns} - ${translate(item?.name) || Strings.albuns.newItem
				}`
			)
		);
	}

	async getData() {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));
		this.setState({ drawerLoading: true });

		try {
			// new item
			if (match?.params?.id !== "new") {
				const [response] = await Promise.all([
					API.get({
						url: Endpoints.uriAlbuns(match.params.id),
					}),
				]);

				const item = response.data.results.albuns || {};

				this.setState({
					item,
					...item,
				});
			}
		} catch (err) {
			Notification.error({
				message: Strings.albuns.item,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		this.setState({ drawerLoading: false });
		dispatch(setLoader(false));
	}

	async submitItem() {
		const {
			name,
			notificationTitle,
			notificationDescription,
			notifyUsers,
			_active,
			image,
		} = this.state;
		const { dispatch, match } = this.props;

		if (!this.validItem()) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			const body = new FormData();
			body.append("name", JSON.stringify(name));

			if (image && typeof image === "string") {
				body.append("image", image);
			} else if (image && typeof image === "object") {
				body.append("image", image.file);
			}

			body.append("notifyUsers", notifyUsers || false);
			if (translate(notificationTitle)) {
				body.append("notificationTitle", JSON.stringify(notificationTitle));
			}
			if (translate(notificationDescription)) {
				body.append("notificationDescription", JSON.stringify(notificationDescription));
			}

			body.append("_active", _active);

			const request = match?.params?.id === "new" ? API.post : API.put;
			response = await request({
				url: Endpoints.uriAlbuns(match?.params?.id !== 'new' ? match?.params?.id : ""),
				data: body,
			});

			if (response.ok) {
				Notification.success({
					message: Strings.albuns.item,
					description: match?.params?.id === "new" ? Strings.albuns.created : Strings.albuns.edited,
					placement: "bottomRight",
					duration: 5,
				});

				if (match?.params?.id === "new") {
					dispatch(push('/albuns'));
					dispatch(push(`/albuns/${response.data.results.albuns?._id}`));
				}
			} else {
				Notification.error({
					message: Strings.albuns.item,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			Notification.error({
				message: Strings.albuns.item,
				description:
					response?.data?.message || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
			console.log("Error", err);
		}

		dispatch(setLoader(false));
	}

	async addMusic() {
		const { tempMusic, item } = this.state;
		const { dispatch } = this.props;

		if (!this.validMusic()) {
			return;
		}

		dispatch(setLoader(true));

		let response: any;
		try {
			const body = new FormData();
			body.append("name", JSON.stringify(tempMusic.name));
			body.append("description", JSON.stringify(tempMusic.description));

			if (tempMusic.musicFile && typeof tempMusic.musicFile === "string") {
				body.append("musicFile", tempMusic.musicFile);
			} else if (tempMusic.musicFile && typeof tempMusic.musicFile === "object") {
				body.append("musicFile", tempMusic.musicFile.file);
			}

			const request = tempMusic?._id ? API.put : API.post;
			response = await request({
				url: Endpoints.uriAlbuns(`${item._id}/music/${tempMusic?._id || ''}`),
				data: body,
			});

			if (response.ok) {
				const item = response.data.results.albuns || {};

				this.setState({
					item,
					...item,
					openMusicDrawer: false
				});

				Notification.success({
					message: Strings.albuns.musics,
					description: tempMusic?._id ? Strings.albuns.musicEdited : Strings.albuns.musicAdded,
					placement: "bottomRight",
					duration: 5,
				});

				this.setState({ tempContract: {}, openContractDrawer: false });
			} else {
				Notification.error({
					message: Strings.clients.contracts,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			Notification.error({
				message: Strings.clients.contracts,
				description: response?.data?.message || (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async deleteMusic(id: string) {
		const { item } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.delete({
				url: Endpoints.uriAlbuns(`${item._id}/music/${id}`),
			});

			if (response.ok) {
				const item = response.data.results.albuns || {};

				this.setState({
					item,
					musics: item.musics,
					...item,
				});

				Notification.success({
					message: Strings.albuns.musics,
					description: Strings.albuns.musicRemoved,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				Notification.error({
					message: Strings.albuns.musics,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			Notification.error({
				message: Strings.albuns.musics,
				description: response?.data?.message || (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	validItem() {
		const {
			name,
			image,
			notificationTitle,
			notificationDescription,
			notifyUsers,
		} = this.state;

		if (!name) {
			Notification.warn({
				message: Strings.albuns.item,
				description: Strings.albuns.nameMandatory,
				placement: 'bottomRight',
				duration: 5,
			});

			return false;
		}

		if (!image) {
			Notification.warn({
				message: Strings.albuns.item,
				description: Strings.albuns.imageMandatory,
				placement: 'bottomRight',
				duration: 5,
			});

			return false;
		}

		if (
			notifyUsers &&
			(!translate(notificationTitle) ||
				!translate(notificationDescription))
		) {
			Notification.warn({
				message: Strings.albuns.item,
				description: Strings.albuns.notificationMandatory,
				placement: 'bottomRight',
				duration: 5,
			});

			return false;
		}

		return true;
	}

	validMusic() {
		const {
			tempMusic,
		} = this.state;

		if (!tempMusic.name) {
			Notification.warn({
				message: Strings.albuns.audio,
				description: Strings.albuns.nameMandatory,
				placement: 'bottomRight',
				duration: 5,
			});

			return false;
		}

		if (!tempMusic.description) {
			Notification.warn({
				message: Strings.albuns.audio,
				description: Strings.albuns.nameMandatory,
				placement: 'bottomRight',
				duration: 5,
			});

			return false;
		}

		if (!tempMusic.musicFile) {
			Notification.warn({
				message: Strings.albuns.audio,
				description: Strings.albuns.audioMandatory,
				placement: 'bottomRight',
				duration: 5,
			});

			return false;
		}

		return true;
	}

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: any, type?: any) {
		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						this.setState((prevState: any) => ({
							filesToDelete:
								prevState[type] === "string"
									? prevState.filesToDelete.concat(
										prevState[type]
									)
									: prevState.filesToDelete,
							[type]: { file: result, preview: res },
							hasUnsavedFields: true,
						}));
					});
				},
			});
		} catch (err) {
			Notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5,
			});
		}
	}

	onAudioDrop = (files: any) => {
		const { tempMusic } = this.state;
		try {
			const file = files[files.length - 1];

			this.getBase64(file).then((preview) => {
				this.setState({
					tempMusic: {
						...tempMusic,
						musicFile: { preview, file },
					},
					hasUnsavedFields: true,
				});
			});
		} catch (err) {
			console.log("err", err);
			Notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5,
			});
		}
	};

	renderMusicDrawer() {
		const { openMusicDrawer, tempMusic } = this.state;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="calendar" />
						<p>
							{tempMusic?._id
								? Strings.albuns.editMusic
								: Strings.albuns.addMusic}
						</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.addMusic()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({ tempMusic: null, openMusicDrawer: false })
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={400}
				onClose={() => this.setState({ tempMusic: null, openMusicDrawer: false })}
				visible={openMusicDrawer}
			>
				{this.renderMusicContent()}
			</Drawer>
		);
	}

	renderMusicContent() {
		const { tempMusic } = this.state;

		console.log('MUSIC FILE', tempMusic)

		return (
			<Row gutter={[0, 20]}>
				<Col xs={24}>
					{tempMusic?.musicFile ? (
						<div className="DrawerAudioElement">
							<audio controls src={tempMusic.musicFile?.preview || tempMusic.musicFile}>
								Your browser does not support the
								<code>audio</code> element.
							</audio>
							<button
								onClick={(
									e: React.MouseEvent<HTMLElement>
								) => {
									e.stopPropagation();

									this.setState({
										tempMusic: {
											...tempMusic,
											musicFile: null,
										},
										hasUnsavedFields: true,
									});
								}}
								className="DrawerAudioDelete"
							>
								<Icon name="close" />
							</button>
						</div>
					) : (
						<div className="AlbumItem">
							<Dropzone
								accept="audio/mpeg, audio/mp3, audio/mp4, audio/ogg"
								className="AlbumImageUpload"
								onDrop={(files: any) => {
									if (files?.length) {
										this.onAudioDrop(files);
									}
								}}
							>
								<div className="AlbumUploadOverlay --visible">
									<Icon name="volume" />
									<span>
										{Strings.generic.changeAudio}
									</span>
								</div>
							</Dropzone>
						</div>
					)}
				</Col>
				<Col xs={24}>
					<label
						htmlFor="image_name"
						className="InputLabel --label-required"
					>
						{Strings.fields.name}
					</label>
					<Input
						id="image_name"
						value={tempMusic?.name || ""}
						placeholder={Strings.fields.name}
						onChange={(event: any) => {
							const value = event.target.value;
							this.setState((prevState: any) => ({
								tempMusic: {
									...tempMusic,
									name: value,
								},
								hasUnsavedFields: true,
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label
						htmlFor="image_name"
						className="InputLabel --label-required"
					>
						{Strings.fields.description}
					</label>
					<Input
						id="image_name"
						value={tempMusic?.description || ""}
						placeholder={Strings.fields.description}
						onChange={(event: any) => {
							const value = event.target.value;
							this.setState((prevState: any) => ({
								tempMusic: {
									...tempMusic,
									description: value,
								},
								hasUnsavedFields: true,
							}));
						}}
					/>
				</Col>
			</Row>
		);
	}

	renderAlbum() {
		const { image, name } = this.state;

		return (
			<ContentWrapper extraStyle={{ padding: 20 }}>
				<Row gutter={[20, 20]}>
					<Col xs={24} md={6}>
						<div className="AlbumDetail">
							<Dropzone
								accept="image/jpg, image/jpeg, image/png"
								className="GenericImageUpload"
								onDrop={(files: any) => this.onDrop(files, 'image')}
							>
								{image ? (
									<div
										className="AlbumDetailImage"
										style={{
											backgroundImage:
												`url('${image.preview || image
												}')` || "none",
										}}
									/>
								) : (
									<div
										className={`GenericImageUploadOverlay${!image ? " --visible" : ""
											}`}
									>
										<Icon name="frame" />
										<span>
											{Strings.albuns.changeImage}
										</span>
									</div>
								)}
								{image && (
									<button
										onClick={(
											e: React.MouseEvent<HTMLElement>
										) => {
											e.stopPropagation();

											this.setState({
												image: null,
												hasUnsavedFields: true,
											});
										}}
										className="GenericImageDelete"
									>
										<Icon name="close" />
									</button>
								)}
							</Dropzone>
						</div>
					</Col>
					<Col xs={24} md={18}>
						<Row gutter={[20, 10]}>
							<Col xs={24} md={24}>
								<label
									htmlFor="image_name"
									className="InputLabel --label-required"
								>
									{Strings.fields.name}
								</label>
								<Input
									id="image_name"
									value={name || ""}
									placeholder={Strings.fields.name}
									onChange={(event: any) => {
										const value = event.target.value;
										this.setState((prevState: any) => ({
											name: value,
											hasUnsavedFields: true,
										}));
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderNotification() {
		const {
			language,
			notifyUsers,
			notificationTitle,
			notificationDescription,
			sent
		} = this.state;

		return (
			<ContentWrapper extraStyle={{ padding: 20 }}>
				<div className="GenericHeader">
					<div className="GenericTitle">
						<Icon name="bell1" />
						<p>{Strings.albuns.notificationText}</p>
					</div>
					<div className="NotificationSendEmail">
						<p>{Strings.notifications.sendNotification}</p>
						<Switch
							checked={notifyUsers}
							size="small"
							onClick={() =>
								this.setState((prevState: any) => ({
									notifyUsers:
										!prevState.notifyUsers,
									hasUnsavedFields: true,
								}))
							}
						/>
					</div>
				</div>
				<Row gutter={[0, 20]}>
					<Col xs={24}>
						<label
							htmlFor="notification_title"
							className="InputLabel --label-required"
						>
							{Strings.pages.title}
						</label>
						<Input
							id="notification_title"
							value={
								notificationTitle?.[language] ||
								""
							}
							disabled={!notifyUsers || sent}
							placeholder={Strings.pages.title}
							onChange={(event: any) => {
								const value =
									event.target.value;
								this.setState(
									(prevState: any) => ({
										notificationTitle: {
											...prevState.notificationTitle,
											[language]: value,
										},
										hasUnsavedFields: true,
									})
								);
							}}
						/>
					</Col>
					<Col xs={24}>
						<label
							htmlFor="notification_text"
							className="InputLabel --label-required"
						>
							{Strings.albuns.notificationText}
						</label>
						<Input.TextArea
							id="notification_text"
							value={
								notificationDescription?.[
								language
								] || ""
							}
							rows={4}
							disabled={!notifyUsers || sent}
							placeholder={
								Strings.albuns.notificationText
							}
							onChange={(event: any) => {
								const value =
									event.target.value;
								this.setState(
									(prevState: any) => ({
										notificationDescription:
										{
											...prevState.notificationDescription,
											[language]:
												value,
										},
										hasUnsavedFields: true,
									})
								);
							}}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderMusics() {
		const { musics } = this.state;

		console.log('MUSICS', musics);

		return (
			<Table
				title={{
					icon: "volume",
					title: Strings.albuns.musics,
				}}
				data={musics}
				columns={[
					{
						Header: Strings.fields.name,
						id: 'name',
						accessor: (row: any) => row.name,
					},
				]}
				paginated={false}
				add={{
					onClick: () => this.setState({ tempMusic: {}, openMusicDrawer: true }),
				}}
				actions={{
					edit: (original: any) => ({
						onClick: () => this.setState({ tempMusic: JSON.parse(JSON.stringify(original)), openMusicDrawer: true }),
					}),
					remove: (original: any) => ({
						onClick: () => this.deleteMusic(original._id),
					}),
				}}
			/>
		);
	}

	render() {
		const { item } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.albuns}</title>
					<meta name="description" content="Description of Albuns" />
				</Helmet>
				<div className="AlbumDetailScreen">
					{this.renderAlbum()}
					{!item?._active ? this.renderNotification() : null}
					{this.renderMusics()}
				</div>
				{this.renderMusicDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(AlbumDetail);
