import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
	/* cSpell:enable */
	/* cSpell.language:en */
	en: {
		// Generic
		generic: {
			askToSave: "Deseja guardar as alterações antes de sair da página?",
			edit: 'Edit',
			done: 'Done',
			accept: 'Accept',
			cancel: 'Cancel',
			remove: 'Remove',
			forget: 'Forget User',
			delete: 'Delete',
			success: 'Success',
			active: 'Active',
			expand: 'Expand',
			toggle: 'Toggle',
			save: 'Save',
			yes: 'Yes',
			no: 'No',
			apply: 'Apply',
			item: 'Item',
			items: 'Items',
			submit: 'Submit',
			continue: 'Continue',
			confirm: 'Confirm',
			finish: 'Finish',
			back: 'Back',
			oops: 'Oops',
			error: 'Error',
			retry: 'Retry',
			reset: 'Reset',
			search: 'Search',
			noResults: 'No Results',
			clickHereTo: 'Click here to {0}',
			requiredField: '{0} is a required field',
			duplicateEmail: 'This email is already taken. Please use another email.',
			emptyList: 'No {0} in List',
			leavePage: 'Leave Page?',
			leave: 'Leave',
			stay: 'Stay',
			open: 'Open',
			close: 'Close',
			change: 'Change',
			free: 'Free',
			send: 'Send',
			selected: 'Selected',
			selecteds: 'Selected',
			swalEditTitle: 'Cancel Edit?',
			swalEditDescription: 'Cancelling edit will erase all the unsaved information!',
			leaveDescription: 'Leaving this page will erase all the unsaved information!',
			swalDeleteTitle: 'Delete {0}?',
			swalDeleteDescription: "By leaving this page, you'll erase all the {0} on your list",
			genericSelect: 'Select {0}',
			select: 'Select',
			addGeneric: 'Add {0}',
			moreOptions: 'More Options',
			all: 'All',
			enable: 'Enable',
			newF: 'New',
			newM: 'New',
			addNewF: 'Add new {0}',
			addNewM: 'Add new {0}',
			default: 'Default',
			editGeneric: 'Edit {0}',
			infinite: 'Infinite',
			details: 'Details',
			noItemsSelected: 'No Items Selected',
			clearList: 'Clear List',
			loading: 'Loading...',
			view: 'View',
			actions: 'Actions',
			invalidUrl: 'Invalid URL. Please remove HTTP prefix.',
			shop: 'Shop',
			noEntries: 'No Entries',
			chatWalktroughText1:
				"We've added several FAQs that will help you know and understand how the Shop2Grow product works. These FAQs are available from the chat icon at the bottom right of the screen.",
			chatWalktroughText2:
				'We are also available through chat to help you the best way on to setting up your store.',
			skip: 'Skip',
			walkTrough: 'walkthrough',
			clone: 'Clone',
			download: 'Download',
			upload: 'Upload',
			template: 'Download Template',
			export: 'Export',
			import: 'Import',
			defaultItem: 'an item',
			defaultItemRecipient: 'this {0}',
			demo: 'Demo',
			previewOnly: 'Preview Only',
			selectPaymentMethod: 'Please select a payment method before updating your subscription.',
			basic: 'Basic',
			advanced: 'Advanced',
			editMode: 'Edit Mode',
			print: 'Print',
			code: 'Code',
			filters: ' Filters',
			changeImage: 'Click here to add an image file or drag it into this box.',
			userImage: `This user don't have any image`,
			changeVideo: 'Click here to add a video file or drag it into this box.',
			changeYoutubeVideo: 'Insert youtube link',
			changeAudio: 'Click here to add an audio file or drag it into this box.',
			everyF: 'All',
		},

		fields: {
			name: 'Name',
			externalName: 'Nome Externo',
			address: 'Address',
			addresses: 'Addresses',
			country: 'Country',
			state: 'State',
			city: 'City',
			zipCode: 'Zip Code',
			description: 'Description',
			phone: 'Phone',
			phoneNumber: 'Phone Number',
			phoneShort: 'Tel.:',
			mobilePhone: 'Mobile Number',
			email: 'Email',
			seoUrl: 'SEO Url',
			tags: 'Tags',
			ccNumber: 'CC Number',
			billingAddress: 'Billing Address',
			shippingAddress: 'Shipping Address',
			vat: 'VAT',
			password: 'Password',
			message: 'Message',
			image: 'Image',
			images: 'Images',
			imgPlaceholder: 'Try dropping some images here, or click to select files to upload',
			filesPlaceholder: 'Try dropping your file here, or click to select a file',
			subTotal: 'Subtotal',
			total: 'Total',
			quantity: 'Quantity',
			ordered: 'Ordered',
			totalQuantity: 'Total Quantity',
			discount: 'Discount',
			registerDate: 'Register Date',
			invalidEmail: 'Email is not Valid',
			tax: 'Tax',
			addedOn: 'Added On',
			startDate: 'Start Date',
			endDate: 'End Date',
			startHour: 'Start Hour',
			endHour: 'End Hour',
			filename: 'Filename',
			gender: 'Gender',
			waitingForResults: 'Waiting for Name or SKU...',
			link: 'Link',
			date: 'Date',
			otherRegions: 'Other Zones',
			taxValues: 'Tax Values',
			previewLink: 'Preview Link',
			clickToUpload: 'Click to Upload',
			photo: 'Photo',

			// Select
			selectCountry: 'Select Country',
			selectState: 'Select State',
			selectFilters: 'Select Filters',
			selectDiscount: 'Select Discount',

			countries: 'Countries',
			states: 'States',

			// Pairs
			valuePair: '{0} / {1}',
			placeHolderPair: '{0} => {1}',

			code: 'Abbreviation',
			bccEmail: 'Email to receive orders',
			article: 'Article',

			logo: 'Logo',
			category: 'Category'
		},

		language: {
			header: 'Language',
			defaultLanguage: 'Default Language',
			languageNotActive: 'Save the changes before changing the default language.',
			portuguese: 'Portuguese',
			english: 'English',
			german: 'German',
			french: 'French',
			spanish: 'Spanish',
		},

		sidebar: {
			dashboard: 'Dashboard',
			profile: 'Profile',
			information: 'Information',
			staff: 'Staff',
			settings: 'Settings',
			logs: 'Logs',
			users: 'Users',
			clients: 'Clients',
			psychologists: 'Psychologists',
			appointments: 'Appointments',
			gallery: 'Library',
			albuns: 'Albuns',
			books: 'Books',
			notifications: 'Notifications',
			patients: 'Patients',
		},

		header: {
			daysLeft: 'days left on trial',
			upgrade: 'Upgrade',
			account: 'Account',
			maintenance: 'Maintenance',
			visitStore: 'Visit Store',
			deleteSuccess: 'Dummy data deleted successfully',
			extend: 'Extend Trial',
		},

		// Screens
		authentication: {
			welcome: 'Welcome',
			header: 'Authentication',
			register: 'Register',
			login: 'Login',
			logout: 'Logout',
			signIn: 'Sign in',
			confirmAccount: 'Confirm Account',
			name: 'Name',
			namePlaceholder: 'Your name',
			email: 'Email',
			emailPlaceholder: 'Your email',
			password: 'Password',
			passwordPlaceholder: 'Your password',
			newPassword: 'New password',
			newPasswordPlaceholder: 'Enter new password',
			confirmPassword: 'Confirm Password',
			confirmPasswordPlaceholder: 'Repeat your password',
			forgotPassword: 'Forgot your password? Click here.',
			loginFooter: 'No account? Register here',
			registerFooter: 'Do you have an account? Login here',
			resendCode1: 'Have you not received your confirmation code? ',
			resendCode2: 'Resend activation code',
			sendEmail: 'Send Email',
			recoverPassword: 'Recover Password',
			changePassword: 'Change Password',
			changeEmail: 'Change Email',
			newEmail: 'New Email',
			repeatEmail: 'Repeat Email',
			newEmailConfirm: 'Confirm New Email',
			currentEmail: 'Current Email',
			currentPassword: 'Current Password',
			emailSent: 'Email Sent Successfully',
			repeatPassword: 'Repeat Password',
			rememberedPassword: 'Did you remember your password?',
			adminPassword: 'Admin Password',
			notLoggedIn: 'You need to be logged in to access this page',
			noMatchPassword: 'Passwords don\'t match',
			acceptSubtitle: 'Please input a password to confirm your account.',
			confirmInvite: 'Please type your password to accept invite.',
			goBackToLogin: 'Go to the login screen',
			resendInviteEmail: 'Resend Invitation Email',
			confirmResendInviteEmail: 'Resend an invitation email to {0}?',
			forgetTitle: 'Are you sure you want to forget the user {0}? This action will leave the user without access to the app and all the user information will be removed.',
			forgetConfirm: 'Forget User',
		},

		acceptInvite: {
			header: 'Accept Invite',
			title: 'Hi {0}, {1} has invited you to belong {2}',
			title2: 'Hi {0}, {1} has invited you to manage {2}',
			subTitle: 'Please choose a password to confirm your account',
			expired: 'This invitation link has expired',
		},

		notFound: {
			header: 'Not Found',
		},

		importModal: {
			fileName: 'Filename',
			size: 'Size',
			import: 'Import',
		},

		errors: {
			invalidArgs: 'Invalid or missing arguments',
			invalidFields: 'Invalid or missing fields',
			emailIsInvalid: 'Email format is not valid',
			fillEmail: 'Please input your email',
			fillPassword: 'Please input your password',
			pleaseFillFormCorrectly: 'Please fill the form correctly',
			fillField: 'This field is mandatory',
			notSupportedFile: 'File type not supported',
			nameIsEmpty: 'Please fill name field',
			passwordsDontMatch: 'Fields \'New password\' and \'Confirm password\' don\'t match',
			emailIsEmpty: 'Please fill email field',
			fileTooBigorInvalid: 'File size is larger than 8MB or file is corrupt',
			unsupportedFile: 'File not supported',
			fileNotSupported: 'File you tried to upload is not supported.',
			processingError: 'Processing Error',
			imageProcessingError: '{images} could not be processed.',
		},

		profile: {
			profilePicture: 'Profile Picture',
			role: 'Role',
			editedSuccessfully: 'Profile edited successfully',
			recurrence: 'Recurrence',
            recurrenceActive: 'Enable Recurrence',
            recurrenceUntil: 'Recurrent until',
            recurringEndMissing: 'Recurrence end date is mandatory.',
            recurrenceSuccess: 'Recurrence settings saved successfully.',
            deleteRecurrence: 'Delete Recurrence',
            noThankYou: 'No Thank You',
            recurrenceTooltip: 'Create recurrence',
			deleteRecurrenceDescription: 'Do you want to delete recurrence on {date}?',
		},

		placeholders: {
			name: 'Your name',
			email: 'your@email.com',
			phoneNumber: '+351265000000',
			language: 'Select a language option',
			role: 'Select a role',
			currentPassword: 'actual password',
			newPassword: 'new password',
			confirmPassword: 'new password',
			newEmail: 'new@email.com',
			page: 'page',
			titlePage: 'Page Title',
			link: 'https://www.google.com/',
		},

		staff: {
			owner: 'Owner',
			owners: 'Owners',
			admin: 'Administrator',
			editedSuccessfully: 'Staff edited successfully',
			passwordChangedSuccessfully: 'Password changed successfully',
			emailChangedSuccessfully: 'Email changed successfully',
			wrongPassword: 'Wrong password',
			emailInUse: 'Email in use',
			status: 'Status',
			confirmed: 'Confirmed',
			pending: 'Pending',
			createStaff: 'Create Staff',
			createdSuccessfully: 'Staff member has been created successfully.',
			sysAdmin: 'System Admin',
			addStaff: 'Add a Staff Member',
			editStaff: 'Edit Staff Member',
			addOwner: 'Add an Owner',
			editOwner: 'Edit Owner',
			user: 'User',
			removeStaff: 'Remove Staff',
			removed: 'User has been removed as Staff sucessfully.',
			disableStaff: 'Disable Staff Member',
			enableStaff: 'Enable Staff Member',
			disabled: 'Staff member disabled sucessfully.',
			enabled: 'Staff member enabled sucessfully.',
		},

		ranges: {
			today: 'Today',
			thisMonth: 'This month',
			lastMonth: 'Last month',
			thisYear: 'This year',
			lastYear: 'Last year',
		},

		dashboard: {
			period: 'Period',
			active: 'Active',
			homepage: 'Homepage',
			inStock: 'In Stock',
			daily: 'Dialy',
			monthly: 'Monthly',
			newUsers: 'New Users',
			newUsersContent: 'New Users Content',
			uses: 'Uses',
			androidUse: 'Android Usage',
			androidUseContent: 'Android Usage Content',
			iOSUse: 'iOS Usage',
			iOSUseContent: 'iOS Usage Content',
			webUse: 'Web Usage',
			webUseContent: 'Web Usage Content',
			contract: 'Contract',
			sosButton: 'SOS Button',
			usage: 'Users',
			videoAppointments: 'Psychologic video-consultations',
			hotline: 'Psychological Hotline',
			disturbances: 'Diagnosed Disorders',
			contractDetails: 'Contract Details',
			purchased: 'Purchased',
			consumed: 'Used',
			balance: 'Balance',
			scheduled: 'Scheduled Consultations',
			finished: 'Consultations Conducted',
			details: 'See Detail',
			clicked: 'Num. Clicks',
			usageApp: 'App Usage',
			noData: 'No data for the selected period.',
		},

		settings: {
			pages: 'Pages',
			emailTemplates: 'E-mail Settings',
			diagnostics: 'Diagnostics',
			libraryTypes: 'Library Types',
			libraryTypeDeleted: 'Library Type has been deleted successfully.',
			diagnosticCreated: 'Diagnostic has been created successfully.',
			diagnosticUpdated: 'Diagnostic has been edited successfully.',
			diagnosticDeleted: 'Diagnostic has been deleted successfully.',
			editDiagnostic: 'Edit Diagnostic',
			addDiagnostic: 'Add Diagnostic',
		},

		pages: {
			single: 'Page',
			title: 'Title',
			content: 'Content',
			new: 'New Page',
			isLink: 'Redirect',
		},

		templates: {
			single: 'Template',
		},

		emails: {
			single: 'E-mail',
			tags: 'Tags',
			preview: 'Preview',
			subject: 'Subject',
			cc: 'CC',
			templates: 'E-mail Templates',
			invite: 'Staff Invite',
			edited: 'E-mail template saved successfully.',
		},

		serverErrors: {
			title: 'Internal Server Error',
			subTitle: 'It\'s not you, it\'s us!',
			refresh: 'Go Back to Previous Page',
			backToDashboard: 'Go to Dashboard',
			wentWrong: 'Something went wrong.',
		},

		logs: {
			dateRange: 'Date Range',
			userId: 'User Id',
			url: 'Url',
			token: 'Token',
			codes: 'Codes',
			methods: 'Methods',
			sources: 'Sources',
			types: 'Types',
		},

		emailTemplates: {
			generalEmail: 'Generic E-mail',
			accountConfirmationCode: 'User Account Confirmation by Code',
			recoverPasswordCode: 'User Password Recovery by Code',
			accountConfirmationLink: 'User Account Confirmation by Link',
			recoverPasswordLink: 'User Password Recovery by Link',
			reservationEmail: 'User Reservation E-mail',
			staffAccountConfirmation: 'Staff Account Confirmation for BO',
			staffRecoverPassword: 'Staff Recover Password for BO',
		},

		appointments: {
			new: 'New Appointment',
			psychologist: 'Psychologist',
			client: 'Business',
			user: 'User',
			cancelled: 'Cancelled',
			dateInterval: 'Date Interval',
			duration: 'Duration',
			recurring: 'Recurring',
			recurringEndDate: 'Recurring Until',
			information: 'Appointment Details',
		},

		albuns: {
			item: 'Item de Album',
			newItem: 'Novo Item',
			sendNotification: 'Enviar Notificação',
			notificationText: 'Texto da Notificação',
			active: 'Ativo',
			audio: 'Música',
			published: 'Publicado',
			nameMandatory: 'Nome é um campo de preenchimento obrigatório.',
			valueMandatory: 'Valor é um campo de preenchimento obrigatório.',
			imageMandatory: 'Imagem é um campo de preenchimento obrigatório.',
			audioMandatory: 'Música é um campo de preenchimento obrigatório.',
			notificationMandatory: 'O título da notificação ou a descrição da mesma não foi preenchido correctamente.',
			deleted: 'Eliminar conteudo de Album',
			created: 'O album foi criado com sucesso.',
			edited: 'O album foi editado com sucesso.',
			value: 'Valor',
			changeImage: 'Click here to add the image file of the album or drag it into this box.',
			musics: 'Musics',
			addMusic: 'Add Music',
			editMusic: 'Edit Music',
			musicAdded: 'Music Added with Success',
			musicEdited: 'Music Edited with Success',
			musicRemoved: 'Music Removed with Success'
		},

		books: {
			item: 'Item de Livro',
			newItem: 'Novo Item',
			sendNotification: 'Enviar Notificação',
			notificationText: 'Texto da Notificação',
			active: 'Ativo',
			audio: 'Áudio',
			published: 'Publicado',
			nameMandatory: 'Nome é um campo de preenchimento obrigatório.',
			notificationMandatory: 'O título da notificação ou a descrição da mesma não foi preenchido correctamente.',
			deleted: 'Eliminar conteudo de Livro',
			created: 'O livro foi criado com sucesso.',
			edited: 'O livro foi editado com sucesso.',
			value: 'Valor',
			changeImage: 'Click here to add the image file of the book or drag it into this box.',
			changeFile: 'Click here to add the pdf file of the book or drag it into this box.',
			changeFilePreview: 'Click here to add the preview pdf file of the book or drag it into this box.',
			preview: 'Click to preview',
		},

		gallery: {
			item: 'Library Item',
			type: 'Type',
			video: 'Video',
			youtube: 'Youtube Video',
			newItem: 'New Item',
			sendNotification: 'Send Notification',
			notificationText: 'Notification Text',
			content: 'Content',
			specificUsers: 'Specific Clients',
			user: 'Client',
			active: 'Active',
			youtubeLink: 'Youtube Link',
			invalidLink: 'Invalid Link',
			invalidYoutubeLink: 'The link you inserted is not a valid youtube link.',
			addUser: 'Add Clients',
			searchClient: 'Search Clients',
			clientName: 'Client Name',
			audio: 'Audio',
			category: 'Category',
			published: 'Published',
			nameMandatory: 'Name is a mandatory field.',
			categoryMandatory: 'Category is a mandatory field.',
			descriptionMandatory: 'Description is a mandatory field.',
			notificationMandatory: 'Either notification title or notification description is missing.',
			usersMandatory: 'At least one client is mandatory.',
			email: 'E-mail Content',
			deleted: 'Delete Library Content',
			created: 'Library item has been created successfully.',
			edited: 'Library item has been edited successfully.',
			typeUpdated: 'Library type has been updated successfully.',
			typeCreated: 'Library type has been created successfully.',
			addType: 'Add Library Type',
			editType: 'Edit Library Type',
			meditation: 'Meditation', 
			exercises: 'Exercises',
			surveys: 'Surveys', 
			courses: 'Courses',
			question: 'Question',
			questions: 'Questions',
			answer: 'Answer',
			answers: 'Answers',
			result: 'Result',
			results: 'Results',
			minValue: 'Minimum Value',
			maxValue: 'Maximum Value',
			needAtLeastTwo: 'Question needs at least two possible answers.',
			invalidAnswer: 'Last answer is not valid.',
		},

		clients: {
			client: 'Client',
			new: 'New Client',
			header: 'Business',
			plural: 'Businesses',
			info: 'General',
			fullInfo: 'Business Information',
			schedule: 'Schedule',
			settings: 'Settings',
			financial: 'Financial',
			addressLine1: 'Address Line 1',
			addressLine2: 'Address Line 2',
			location: 'Location',
			businessInformation: 'Business Information',
			vatNumber: 'VAT Number',
			invoiceName: 'Invoice Name',
			iban: 'IBAN',
			bic: 'BIC',
			emptyVat: 'Vat number field can not be empty.',
			invalidVat: 'Vat number is not valid.',
			invalidName: 'Name is a mandatory field.',
			invalidOwnerName: 'Owner name is a mandatory field.',
			invalidOwnerEmail: 'Owner e-mail is either empty or invalid.',
			emptyCountry: 'Country is a mandatory field.',
			emptyState: 'State is a mandatory field.',
			ownerPhone: 'Owner Phone',
			emptyOwnerNumber: 'Owner phone number is a mandatory field.',
			approved: 'Approved',
			approve: 'Approve',
			added: 'Business has been created successfully.',
			edited: 'Business has been updated successfully.',
			removed: 'Business has been deleted successfully.',
			categories: 'Categories',
			minPriceRange: 'Min. Price Range',
			maxPriceRange: 'Max. Price Range',
			wallet: 'Wallet',
			emptyAddress: 'Address is a mandatory field.',
			invalidMaxRange: 'Max. price range needs to be higher than min price range.',
			balance: 'Balance',
			allTransactions: 'All transactions',
			monthBalance: 'Month Balance',
			totalAccumulated: 'Accumulated Balance',
			transactions: 'Transactions',
			receiptValue: 'Receipt Amount',
			netBalance: 'Net Balance',
			total: 'Total',
			consultationsHistory: 'Consultation History',
			consultationsBought: 'Bougth Consultations',
			consultationsUsed: 'Used Consultations',
			user: 'User',
			hr: 'HR',
			hrName: 'HR - Name',
			hrNameInvalid: 'Missing HR Name',
			hrEmail: 'HR - Email',
			hrEmailInvalid: 'Missing HR Email',
			hrPhone: 'HR - Phone',
			contracts: 'Contracts',
			value: 'Value',
			consultations: 'Consultations',
			addContract: 'Add contract',
			editContract: 'Edit contract',
			contractAdded: 'Contract has been created successfully.',
			contractEdited: 'Contract has been updated successfully.',
			contractRemoved: 'Contract has been deleted successfully.',
			startDateRequired: 'Contract start date is a mandatory field.',
			endDateRequired: 'Contract end date is a mandatory field.',
			valueRequired: 'Contract consultations is a mandatory field.',
			datesIntervalInvalid: 'Invalid date interval.',
		},

		psychologists: {
			new: 'New Psychologist',
			selectPhoto: 'Click here or drag an image to set profile picture.',
			email: 'E-mail is empty or invalid.',
			created: 'Psychologist has been created successfully.',
			edited: 'Psychologist has been edited successfully.',
			deleted: 'Psychologist has been deleted successfully.',
		},

		notifications: {
			new: 'New Notification',
			title: 'Notification Title',
			message: 'Notification Text',
			email: 'Email Content',
			date: 'Notification Scheduled Date',
			sendEmail: 'Send E-mail',
			sendNotification: 'Send Notification',
			created: 'Notification has been created successfully.',
			edited: 'Notification has been edited successfully.',
			deleted: 'Notification has been deleted successfully.',
			missingTitle: 'Title is a mandatory field.',
			missingDescription: 'Description is a mandatory field.',
			missingDate: 'Scheduled Date is a mandatory field.',
			missingEmailContent: 'Email content is a mandatory field.',
		},

		users: {
			header: 'User',
			fullInfo: 'User\'s Information',
		},

		schedule: {
			header: 'Horário',
			save: "Guardar",
			saveSchedule: "Deseja guardar o horário atual antes de mudar de página?",
			selectDate: "Escolha a data",
			schedule: "Horário",
			week: "Semana",
			office: "Escritório",
			choseOffice: "Escolha um escritório",
			modalOffice: "Deseja guardar o horário atual antes de mudar de escritório?",
			modalWeek: "Deseja guardar o horário atual antes de mudar de semana?",
			scheduleSaved: "Horário gravado com sucesso",
			hours: "Horas",
			sun: "Domingo",
			mon: "Segunda",
			tue: "Terça",
			wed: "Quarta",
			thu: "Quinta",
			fri: "Sexta",
			sat: "Sábado",
			virtual: "Virtual"
		},
	},
	/* cSpell.language:pt,pt_PT */
	pt: {
		// Generic
		generic: {
			askToSave: "Deseja guardar as alterações antes de sair da página?",
			edit: 'Editar',
			done: 'Concluir',
			accept: 'Aceitar',
			cancel: 'Cancelar',
			remove: 'Remover',
			forget: 'Esquecer User',
			delete: 'Eliminar',
			success: 'Sucesso',
			active: 'Ativar',
			expand: 'Expandir',
			toggle: 'Toggle',
			save: 'Guardar',
			yes: 'Sim',
			no: 'Não',
			apply: 'Aplicar',
			item: 'Artigo',
			items: 'Artigos',
			submit: 'Submeter',
			continue: 'Continuar',
			confirm: 'Confirmar',
			finish: 'Terminar',
			back: 'Voltar',
			oops: 'Oops',
			error: 'Erro',
			retry: 'Tentar novamente',
			reset: 'Reset',
			search: 'Pesquisar',
			noResults: 'Sem Resultados',
			clickHereTo: 'Clique para {0}',
			requiredField: '{0} é um campo obrigatório',
			duplicateEmail: 'Este email já está a ser utilizado. Por favor utilize outro email.',
			emptyList: 'Sem {0} na lista',
			leavePage: 'Sair da Página?',
			leave: 'Sair',
			stay: 'Ficar',
			open: 'Abrir',
			close: 'Fechar',
			change: 'Mudar',
			free: 'Grátis',
			send: 'Enviar',
			selected: 'Selecionado',
			selecteds: 'Selecionados',
			swalEditTitle: 'Cancelar Alteração?',
			swalEditDescription: 'Cancelar edição vai apagar toda a informação não guardada',
			leaveDescription: 'Ao sair desta página vai apagar toda a informação não guardada',
			swalDeleteTitle: 'Apagar {0}?',
			swalDeleteDescription: 'Ao sair desta página vai apagar todos os {0} na sua lista',
			genericSelect: 'Selecionar {0}',
			select: 'Selecionar',
			addGeneric: 'Adicionar {0}',
			moreOptions: 'Mais Opções',
			all: 'Tudo',
			enable: 'Ativar',
			newF: 'Nova',
			newM: 'Novo',
			addNewF: 'Adicionar nova {0}',
			addNewM: 'Adicionar novo {0}',
			default: 'Pré-Definido',
			editGeneric: 'Editar {0}',
			infinite: 'Infinito',
			details: 'Detalhes',
			noItemsSelected: 'Nenhum Item Selecionado',
			clearList: 'Limpar lista',
			loading: 'A Carregar...',
			view: 'Ver',
			actions: 'Ações',
			invalidUrl: 'URL inválido. Por favor remova o prefixo HTTP.',
			shop: 'Loja',
			noEntries: 'Sem Valores',
			chatWalktroughText1:
				'Adicionámos diversas FAQ que podem ajudar a conhecer e entender como funciona o produto Shop2Grow. Essas FAQ estão disponíveis no ícone de chat no canto inferior direito do ecrã.',
			chatWalktroughText2:
				'Estamos também disponíveis, através de chat, para ajudar na melhor maneira de criar e definir a sua loja.',
			skip: 'Saltar Walkthrough',
			walkTrough: 'Walkthrough',
			clone: 'Clonar',
			download: 'Download',
			upload: 'Upload',
			template: 'Download Template',
			export: 'Exportar',
			import: 'Importar',
			defaultItem: 'um item',
			defaultItemRecipient: 'este/esta {0}',
			demo: 'Demo',
			previewOnly: 'Apenas Preview',
			selectPaymentMethod: 'Por favor, selecione um método de pagamento antes de atualizar a sua subscrição.',
			basic: 'Básico',
			advanced: 'Avançado',
			editMode: 'Modo de Edição',
			print: 'Imprimir',
			code: 'Codigo',
			filters: 'Filtros',
			changeImage: 'Clique para adicionar uma imagem ou arraste o ficheiro para esta caixa.',
			userImage: `Este utilizador ainda não tem imagem`,
			changeVideo: 'Clique para adicionar um ficheiro de video ou arraste o ficheiro para esta caixa.',
			changeYoutubeVideo: 'Insira um link de youtube',
			changeAudio: 'Clique para adicionar um ficheiro de áudio ou arraste o ficheiro para esta caixa.',
			everyF: 'Todas',
		},

		fields: {
			name: 'Nome',
			address: 'Morada',
			addresses: 'Moradas',
			country: 'País',
			state: 'Estado',
			city: 'Cidade',
			zipCode: 'Código Postal',
			description: 'Descrição',
			phone: 'Telefone',
			phoneNumber: 'Número de Telefone',
			phoneShort: 'Tel.:',
			mobilePhone: 'Telemóvel',
			email: 'Email',
			seoUrl: 'SEO Url',
			tags: 'Tags',
			ccNumber: 'Número do CC',
			billingAddress: 'Morada de Faturação',
			shippingAddress: 'Morada de Envio',
			vat: 'VAT',
			password: 'Password',
			message: 'Mensagem',
			image: 'Imagem',
			images: 'Imagens',
			imgPlaceholder: 'Tente arrastar imagens para aqui, ou clique para fazer upload',
			filesPlaceholder: 'Tente arrastar o ficheiro para aqui ou clique para selecionar o ficheiro',
			subTotal: 'Subtotal',
			total: 'Total',
			quantity: 'Quantidade',
			ordered: 'Qtd. Encomendada',
			totalQuantity: 'Quantidade Total',
			discount: 'Desconto',
			registerDate: 'Data Registo',
			invalidEmail: 'Email não é valido',
			tax: 'Taxa',
			addedOn: 'Adicionado',
			startDate: 'Data Inicial',
			endDate: 'Data Final',
			startHour: 'Hora Inicial',
			endHour: 'Hora Final',
			filename: 'Nome do Ficheiro',
			gender: 'Género',
			waitingForResults: 'À espera do nome ou SKU...',
			link: 'Link',
			date: 'Data',
			otherRegions: 'Outras Zonas',
			taxValues: 'Taxas',
			previewLink: 'Link de Pré-Visualização',
			clickToUpload: 'Clique para fazer o Upload',
			photo: 'Foto',

			// Select
			selectCountry: 'Selecionar País',
			selectState: 'Selecionar Estado',
			selectFilters: 'Selecionar Filtros',
			selectDiscount: 'Selecionar Desconto',

			countries: 'Países',
			states: 'Estados',

			// Pairs
			valuePair: '{0} / {1}',
			placeHolderPair: '{0} => {1}',

			code: 'Abreviatura',
			bccEmail: 'Email para receber encomendas',
			article: 'Artigo',

			logo: 'Logo',
			category: 'Categoria'
		},

		language: {
			header: 'Idioma',
			defaultLanguage: 'Idioma Padrão',
			languageNotActive: 'Guarde as atualizações antes de proceder à alteração do idioma padrão.',
			portuguese: 'Português',
			english: 'Inglês',
			german: 'Alemão',
			french: 'Francês',
			spanish: 'Espanhol',
		},

		sidebar: {
			dashboard: 'Dashboard',
			profile: 'Perfil',
			information: 'Informação',
			staff: 'Staff',
			settings: 'Definições',
			logs: 'Logs',
			users: 'Utilizadores',
			clients: 'Clientes',
			psychologists: 'Psicólogos',
			appointments: 'Marcações',
			gallery: 'Biblioteca',
			albuns: 'Albuns',
			books: 'Livros',
			notifications: 'Notificações',
			patients: 'Pacientes',
		},

		header: {
			daysLeft: 'dias restantes de trial',
			upgrade: 'Upgrade',
			account: 'Conta',
			maintenance: 'Manutenção',
			visitStore: 'Visitar Loja',
			deleteSuccess: 'Dados Temporários apagados com sucesso',
			extend: 'Aumentar Trial',
		},

		// Screens
		authentication: {
			welcome: 'Bem-vindo',
			header: 'Autenticação',
			register: 'Registo',
			login: 'Entrar',
			logout: 'Sair',
			signIn: 'Autenticar',
			confirmAccount: 'Confirmar Conta',
			name: 'Nome',
			namePlaceholder: 'O seu nome',
			email: 'Email',
			emailPlaceholder: 'O seu email',
			password: 'Password',
			passwordPlaceholder: 'A sua password',
			newPassword: 'Nova password',
			newPasswordPlaceholder: 'Inserir nova password',
			confirmPassword: 'Confirmar Password',
			confirmPasswordPlaceholder: 'Repetir a password',
			forgotPassword: 'Esqueceu-se da password?',
			loginFooter: 'Não tem conta? Registe-se aqui',
			registerFooter: 'Já tem conta? Autentique-se aqui.',
			resendCode1: 'Não recebeu o seu código de confirmação? ',
			resendCode2: 'Reenviar código de ativação',
			sendEmail: 'Enviar Email',
			recoverPassword: 'Recuperar password',
			changePassword: 'Mudar Password',
			changeEmail: 'Mudar Email',
			newEmail: 'Novo Email',
			newEmailConfirm: 'Confirmar Novo Email',
			repeatEmail: 'Repetir Email',
			currentEmail: 'Email Atual',
			currentPassword: 'Password Atual',
			emailSent: 'Email Enviado com Sucesso',
			repeatPassword: 'Repetir Password',
			rememberedPassword: 'Lembrou-se da password?',
			adminPassword: 'Password do Administrador',
			notLoggedIn: 'Necessita de estar autenticado para ter acesso a esta página',
			noMatchPassword: 'As passwords não coincidem',
			acceptSubtitle: 'Por favor insira uma password para confirmar a sua conta.',
			confirmInvite: 'Por favor insira a sua password para aceitar o convite.',
			goBackToLogin: 'Ir para o ecrã de início de sessão',
			resendInviteEmail: 'Reenviar email de convite',
			confirmResendInviteEmail: 'Reenviar email de convite para {0}?',
			forgetTitle: 'Tem a certeza que deseja esquecer o utilizador {0}? Esta ação irá deixar o utilizar sem acesso à app, e todos os dados serão removidos.',
			forgetConfirm: 'Forget User',
		},

		acceptInvite: {
			header: 'Aceitar Convite',
			title: 'Olá {0}, {1} convidou-o(a) a pertencer a {2}.',
			title2: 'Olá {0}, {1} convidou-o(a) para gerir {2}.',
			subTitle: 'Insira uma password para confirmar a sua conta',
			expired: 'Este convite expirou',
		},

		notFound: {
			header: 'Página Inexistente',
		},

		importModal: {
			fileName: 'Nome do Ficheiro',
			size: 'Tamanho',
			import: 'Importar',
		},

		errors: {
			invalidArgs: 'Argumentos inválidos ou em falta',
			invalidFields: 'Campos inválidos ou em falta',
			emailIsInvalid: 'O formato de email inserido não é válido',
			fillEmail: 'Insira um email por favor',
			fillPassword: 'Insira uma password por favor',
			pleaseFillFormCorrectly: 'Por favor preencha o formulário correctamente',
			fillField: 'Este campo é de preenchimento obrigatório',
			notSupportedFile: 'Tipo de ficheiro não suportado',
			nameIsEmpty: 'O campo nome é de preenchimento obrigatório',
			passwordsDontMatch: 'Os campos \'Nova password\' e \'Confirmar password\' não coincidem',
			emailIsEmpty: 'O campo email é de preenchimento obrigatório',
			fileTooBigorInvalid: 'O tamanho da imagem é superior a 8MB ou tem um formato inválido',
			unsupportedFile: 'Ficheiro não suportado',
			fileNotSupported: 'O ficheiro que tentou carregar, não é suportado.',
			processingError: 'Erro de Processamento',
			imageProcessingError: '{images} não puderam ser processadas.',
		},

		profile: {
			profilePicture: 'Foto de Perfil',
			role: 'Cargo',
			editedSuccessfully: 'Perfil editado com sucesso',
			recurrence: 'Recorrência',
            recurrenceActive: 'Ativar Recorrência',
            recurrenceUntil: 'Recorrente até',
            recurringEndMissing: 'A data final de recorrência é obrigatória.',
            recurrenceSuccess: 'Definições de recorrência guardadas com sucesso.',
            deleteRecurrence: 'Eliminar recorrência',
            noThankYou: 'Não Obrigado(a)',
            recurrenceTooltip: 'Criar recorrência',
            deleteRecurrenceDescription: 'Pretende eliminar a recorrência do dia {date}?',
		},

		placeholders: {
			name: 'O seu nome',
			email: 'o_seu@email.com',
			phoneNumber: '+351265000000',
			language: 'Selecione uma língua',
			role: 'Selecione um cargo',
			currentPassword: 'password actual',
			newPassword: 'nova password',
			confirmPassword: 'nova password',
			newEmail: 'novo@email.com',
			page: 'pagina',
			titlePage: 'Título da Página',
			link: 'https://www.google.com/',
		},

		staff: {
			owner: 'Owner',
			owners: 'Owners',
			admin: 'Administrator',
			editedSuccessfully: 'Staff edited successfully',
			passwordChangedSuccessfully: 'Password changed successfully',
			emailChangedSuccessfully: 'Email changed successfully',
			wrongPassword: 'Wrong password',
			emailInUse: 'Email in use',
			status: 'Status',
			confirmed: 'Confirmed',
			pending: 'Pending',
			createStaff: 'Create Staff',
			createdSuccessfully: 'Staff member has been created successfully.',
			sysAdmin: 'System Admin',
			addStaff: 'Add a Staff Member',
			editStaff: 'Edit Staff Member',
			addOwner: 'Add an Owner',
			editOwner: 'Edit Owner',
			user: 'User',
			removeStaff: 'Remove Staff',
			removed: 'User has been removed as Staff sucessfully.',
			disableStaff: 'Disable Staff Member',
			enableStaff: 'Enable Staff Member',
			disabled: 'Staff member disabled sucessfully.',
			enabled: 'Staff member enabled sucessfully.',
		},

		ranges: {
			today: 'Hoje',
			thisMonth: 'Este mês',
			lastMonth: 'Último mês',
			thisYear: 'Este ano',
			lastYear: 'Último ano',
		},

		dashboard: {
			period: 'Período',
			active: 'Ativo',
			homepage: 'Homepage',
			inStock: 'Em Stock',
			daily: 'Diário',
			monthly: 'Mensal',
			newUsers: 'Novos Utilizadores',
			newUsersContent: 'Novos Utilizadores Conteúdo',
			uses: 'Utilizações',
			androidUse: 'Utilização Android',
			androidUseContent: 'Utilização Android Conteúdo',
			iOSUse: 'Utilização iOS',
			iOSUseContent: 'Utilização iOS Conteúdo',
			webUse: 'Utilização Web',
			webUseContent: 'Utilização Web Conteúdo',
			contract: 'Contrato',
			sosButton: 'Botão SOS',
			usage: 'Utilizadores',
			videoAppointments: 'Vídeo-consultas de Psicologia',
			hotline: 'Linhas de Apoio Psicológico',
			disturbances: 'Perturbações Diagnosticadas',
			contractDetails: 'Detalhe Contrato',
			purchased: 'Contratadas',
			consumed: 'Consumidas',
			balance: 'Balanço',
			scheduled: 'Consultas Marcadas',
			finished: 'Consultas Realizadas',
			details: 'Ver Detalhe',
			clicked: 'N.º Vezes Clicado',
			usageApp: 'Utilização App',
			noData: 'Não existe data registada no período selecionado.',
		},

		settings: {
			pages: 'Páginas',
			emailTemplates: 'Templates de E-mails',
			diagnostics: 'Diagnósticos',
			libraryTypes: 'Tipos de Biblioteca',
			libraryTypeDeleted: 'O Tipo de Biblioteca foi apagado com sucesso.',
			diagnosticCreated: 'O Diagnóstico foi criado com sucesso.',
			diagnosticUpdated: 'O Diagnóstico foi editado com sucesso.',
			diagnosticDeleted: 'O Diagnóstico foi apagado com sucesso.',
			editDiagnostic: 'Editar Diagnóstico',
			addDiagnostic: 'Adicionar Diagnóstico',
		},

		pages: {
			single: 'Página',
			title: 'Título',
			content: 'Conteúdo',
			new: 'Nova Página',
			isLink: 'Redirecionamento',
		},

		templates: {
			single: 'Template',
		},

		emails: {
			single: 'E-mail',
			tags: 'Tags',
			preview: 'Pré-visualização',
			subject: 'Assunto',
			cc: 'CC',
			templates: 'Templates de E-mail',
			invite: 'Convidar Staff',
			edited: 'Template de E-mail editado com sucesso.',
		},

		serverErrors: {
			title: 'Erro de Servidor',
			subTitle: 'Não é você, somos nós!',
			refresh: 'Voltar à Página Anterior',
			backToDashboard: 'Ir para o Dashboard',
			wentWrong: 'Algo correu mal.',
		},

		logs: {
			dateRange: 'Date Range',
			userId: 'User Id',
			url: 'Url',
			token: 'Token',
			codes: 'Codes',
			methods: 'Methods',
			sources: 'Sources',
			types: 'Types',
		},

		emailTemplates: {
			generalEmail: 'E-mail Genérico',
			accountConfirmationCode: 'Confirmação de Conta Utilizador por Código',
			recoverPasswordCode: 'Recuperação de Password por Código',
			accountConfirmationLink: 'Confirmação de Conta Utilizador por Link',
			recoverPasswordLink: 'Recuperação de Password por Link',
			reservationEmail: 'E-mail de Confirmação de Reserva',
			staffAccountConfirmation: 'Confirmação de Conta de Acesso ao BO',
			staffRecoverPassword: 'Recuperação de Password de Acesso ao BO',
		},

		appointments: {
			new: 'Nova Consulta',
			psychologist: 'Psicólogo(a)',
			client: 'Cliente',
			user: 'Utilizador',
			cancelled: 'Cancelada',
			dateInterval: 'Intervalo de Data',
			duration: 'Duração',
			recurring: 'Recorrente',
			recurringEndDate: 'Recorrente Até',
			information: 'Detalhes da Marcação',
		},

		albuns: {
			item: 'Item de Album',
			newItem: 'Novo Item',
			sendNotification: 'Enviar Notificação',
			notificationText: 'Texto da Notificação',
			active: 'Ativo',
			audio: 'Música',
			published: 'Publicado',
			nameMandatory: 'Nome é um campo de preenchimento obrigatório.',
			valueMandatory: 'Valor é um campo de preenchimento obrigatório.',
			imageMandatory: 'Imagem é um campo de preenchimento obrigatório.',
			audioMandatory: 'Música é um campo de preenchimento obrigatório.',
			notificationMandatory: 'O título da notificação ou a descrição da mesma não foi preenchido correctamente.',
			deleted: 'Eliminar conteudo de Album',
			created: 'O album foi criado com sucesso.',
			edited: 'O album foi editado com sucesso.',
			value: 'Valor',
			changeImage: 'Clique para adicionar a imagem do seu album ou arraste o ficheiro para esta caixa.',
			musics: 'Músicas',
			addMusic: 'Adicionar Música',
			editMusic: 'Editar Música',
			musicAdded: 'Música Adicionada com Sucesso',
			musicEdited: 'Música Editada com Sucesso',
			musicRemoved: 'Música Removida com Sucesso'
		},

		books: {
			item: 'Item de Livro',
			newItem: 'Novo Item',
			sendNotification: 'Enviar Notificação',
			notificationText: 'Texto da Notificação',
			active: 'Ativo',
			audio: 'Áudio',
			published: 'Publicado',
			nameMandatory: 'Nome é um campo de preenchimento obrigatório.',
			notificationMandatory: 'O título da notificação ou a descrição da mesma não foi preenchido correctamente.',
			deleted: 'Eliminar conteudo de Livro',
			created: 'O livro foi criado com sucesso.',
			edited: 'O livro foi editado com sucesso.',
			value: 'Valor',
			changeImage: 'Clique para adicionar a imagem do seu livro ou arraste o ficheiro para esta caixa.',
			changeFile: 'Clique para adicionar o ficheiro pdf do seu livro ou arraste o ficheiro para esta caixa.',
			changeFilePreview: 'Clique para adicionar o preview do ficheiro pdf do seu livro ou arraste o ficheiro para esta caixa.',
			preview: 'Clique para visualizar',
		},

		gallery: {
			item: 'Item de Biblioteca',
			type: 'Tipo',
			video: 'Video',
			youtube: 'Video de Youtube',
			newItem: 'Novo Item',
			sendNotification: 'Enviar Notificação',
			notificationText: 'Texto da Notificação',
			content: 'Conteúdo',
			specificUsers: 'Clientes Específicos',
			user: 'Cliente',
			active: 'Ativo',
			youtubeLink: 'Link do Youtube',
			invalidLink: 'Link Inválido',
			invalidYoutubeLink: 'O link que inseriu não é um link de youtube válido.',
			addUser: 'Adicionar Clientes',
			searchClient: 'Procurar Cliente',
			clientName: 'Nome do Cliente',
			audio: 'Áudio',
			category: 'Categoria',
			published: 'Publicado',
			nameMandatory: 'Nome é um campo de preenchimento obrigatório.',
			categoryMandatory: 'Categoria é um campo de preenchimento obrigatório.',
			descriptionMandatory: 'Categoria é um campo de preenchimento obrigatório.',
			notificationMandatory: 'O título da notificação ou a descrição da mesma não foi preenchido correctamente.',
			usersMandatory: 'É necessário pelo menos um cliente quando os clientes específicos estão ativados.',
			email: 'Conteúdo do E-mail',
			deleted: 'Eliminar conteudo de Biblioteca',
			created: 'O item de biblioteca foi criado com sucesso.',
			edited: 'O item de biblioteca foi editado com sucesso.',
			typeUpdated: 'O tipo de biblioteca foi editado com sucesso.',
			typeCreated: 'O tipo de biblioteca foi criado com sucesso.',
			addType: 'Adicionar Tipo de Biblioteca',
			editType: 'Editar Tipo de Biblioteca',
			meditation: 'Meditação', 
			exercises: 'Exercicios',
			surveys: 'Testes', 
			courses: 'Cursos',
			question: 'Questão',
			questions: 'Questões',
			answer: 'Resposta',
			answers: 'Respostas',
			result: 'Resultado',
			results: 'Resultados',
			minValue: 'Valor Mínimo',
			maxValue: 'Valor Máximo',
			needAtLeastTwo: 'A questão precisa no mínimo de duas respostas possíveis.',
			invalidAnswer: 'A última resposta não é válida.',
		},

		psychologists: {
			new: 'Novo(a) Psicólogo(a)',
			selectPhoto: 'Clique aqui ou arraste uma imagem para definir a foto do(a) psicólogo(a).',
			email: 'O campo e-mail está vazio ou inválido.',
			created: 'Psicólogo(a) criado(a) com sucesso.',
			edited: 'Psicólogo(a) editado(a) com sucesso.',
			deleted: 'Psicólogo(a) apagado(a) com sucesso.',
		},

		clients: {
			client: 'Cliente',
			new: 'Novo Cliente',
			header: 'Cliente',
			plural: 'Clientes',
			info: 'Geral',
			deleted: 'Eliminar Cliente',
			fullInfo: 'Informação do Cliente',
			schedule: 'Horário',
			settings: 'Definições',
			financial: 'Financeiro',
			addressLine1: 'Endereço 1',
			addressLine2: 'Endereço 2',
			consultationsPerUser: 'Consultas por Utilizador',
			location: 'Localização',
			businessInformation: 'Informação do Cliente',
			vatNumber: 'NIF',
			invoiceName: 'Nome do Cliente',
			iban: 'IBAN',
			bic: 'BIC',
			emptyVat: 'O campo NIF é de preenchimento obrigatório.',
			invalidVat: 'O NIF introduzido não é válido.',
			invalidName: 'O campo nome é de preenchimento obrigatório.',
			invalidOwnerName: 'O campo nome do proprietário é de preenchimento obrigatório.',
			invalidOwnerEmail: 'O campo e-mail do proprietário é de preenchimento obrigatório.',
			emptyCountry: 'O campo país é de preenchimento obrigatório.',
			emptyState: 'O campo distrito é de preenchimento obrigatório.',
			ownerPhone: 'Telefone do Proprietário',
			emptyOwnerNumber: 'O campo telefone do proprietário é de preenchimento obrigatório.',
			approved: 'Aprovado',
			approve: 'Aprovar',
			added: 'O cliente foi criado com sucesso.',
			edited: 'O cliente foi editado com sucesso.',
			removed: 'O cliente foi apagado com sucesso.',
			categories: 'Categorias',
			minPriceRange: 'Valor Intervalo Mínimo',
			maxPriceRange: 'Valor Intervalo Máximo',
			wallet: 'Carteira',
			emptyAddress: 'O campo endereço é de preenchimento obrigatório.',
			invalidMaxRange: 'O valor de intervalo máximo não pode ser inferior ao valor mínimo.',
			balance: 'Saldo',
			allTransactions: 'Todas as transações',
			monthBalance: 'Saldo Mensal',
			totalAccumulated: 'Saldo Acumulado',
			transactions: 'Transações',
			receiptValue: 'Valor do Recibo',
			netBalance: 'Valor Líquido Recebido',
			total: 'Total',
			consultationsHistory: 'Histórico Consultas',
			consultationsBought: 'Consultas Compradas',
			consultationsUsed: 'Consultas Usadas',
			user: 'Utilizador',
			hr: 'HR',
			hrName: 'RH - Nome',
			hrNameInvalid: 'O campo Nome está em falta.',
			hrEmail: 'RH - Email',
			hrEmailInvalid: 'O campo Email está em falta.',
			hrPhone: 'RH - Telefone',
			contracts: 'Contratos',
			value: 'Valor',
			consultations: 'Consultas',
			addContract: 'Adicionar contrato',
			editContract: 'Editar contrato',
			contractAdded: 'O contrato foi criado com sucesso.',
			contractEdited: 'O contrato foi editado com sucesso.',
			contractRemoved: 'O contrato foi apagado com sucesso.',
			startDateRequired: 'A data inicial do contrato é um campo de preenchimento obrigatório.',
			endDateRequired: 'A data final do contrato é um campo de preenchimento obrigatório.',
			valueRequired: 'O número de consultas é um campo de preenchimento obrigatório.',
			datesIntervalInvalid: 'O intervalo de datas é inválido.',
		},

		notifications: {
			new: 'Nova Notificação',
			title: 'Título da Notificação',
			message: 'Texto da Notificação',
			email: 'Conteúdo do E-mail',
			date: 'Date de Envio',
			sendEmail: 'Enviar E-mail',
			sendNotification: 'Enviar Notificação',
			created: 'A notificação foi criada com sucesso.',
			edited: 'A notificação foi editada com sucesso.',
			deleted: 'A notificação foi apagada com sucesso.',
			missingTitle: 'Título é um campo de preenchimento obrigatório.',
			missingDescription: 'Descrição é um campo de preenchimento obrigatório.',
			missingDate: 'Date de Envio é um campo de preenchimento obrigatório.',
			missingEmailContent: 'Conteúdo do E-mail é um campo de preenchimento obrigatório.',
		},

		users: {
			header: 'Utilizador',
			fullInfo: 'Informação do Utilizador',
			addUser: 'Adicionar um Utilizador',
			editUser: 'Editar um Utilizador',
		},

		schedule: {
			header: 'Schedule',
			save: "Guardar",
			saveSchedule: "Deseja guardar o horário atual antes de mudar de página?",
			selectDate: "Escolha a data",
			schedule: "Horário",
			week: "Semana",
			office: "Escritório",
			choseOffice: "Escolha um escritório",
			modalOffice: "Deseja guardar o horário atual antes de mudar de escritório?",
			modalWeek: "Deseja guardar o horário atual antes de mudar de semana?",
			scheduleSaved: "Horário gravado com sucesso",
			hours: "Horas",
			sun: "Domingo",
			mon: "Segunda",
			tue: "Terça",
			wed: "Quarta",
			thu: "Quinta",
			fri: "Sexta",
			sat: "Sábado",
			virtual: "Virtual"
		},
	},
});
